const actions = {
  // GET WORKSHEET
  GET_WORKSHEET: 'GET_WORKSHEET',
  GET_WORKSHEET_SUCCESS: 'GET_WORKSHEET_SUCCESS',
  GET_WORKSHEET_ERROR: 'GET_WORKSHEET_ERROR',
  // FETCH DATA
  FETCH_ATTRS: 'FETCH_ATTRS',
  FETCH_ATTRS_SUCCESS: 'FETCH_ATTRS_SUCCESS',
  FETCH_ATTRS_ERROR: 'FETCH_ATTRS_ERROR',
  // USER DATA
  SAVE_VIEW_DATA: 'SAVE_VIEW_DATA',
  SAVE_MAPI_DATA: 'SAVE_MAPI_DATA',
  SAVE_MARKETING_DATA: 'SAVE_MARKETING_DATA',
  SAVE_SELECTED_DECISION: 'SAVE_SELECTED_DECISION',
  RESET_USER_DATA: 'RESET_USER_DATA',
  // DECISIONS
  GET_DECISION: 'GET_DECISION',
  GET_DECISION_SUCCESS: 'GET_DECISION_SUCCESS',
  GET_DECISION_ERROR: 'GET_DECISION_ERROR',
  // MAPI
  POST_MAPI_DATA: 'POST_MAPI_DATA',
  POST_MAPI_DATA_SUCCESS: 'POST_MAPI_DATA_SUCCESS',

  getWorkSheet: data => ({
    type: actions.GET_WORKSHEET,
    payload: data,
  }),
  getWorkSheetSuccess: data => ({
    type: actions.GET_WORKSHEET_SUCCESS,
    payload: data,
  }),
  getWorkSheetError: error => ({
    type: actions.GET_WORKSHEET_ERROR,
    payload: error,
  }),

  fetchAttrs: () => ({
    type: actions.FETCH_ATTRS,
  }),
  fetchAttrsSuccess: data => ({
    type: actions.FETCH_ATTRS_SUCCESS,
    payload: data,
  }),
  fetchAttrsError: error => ({
    type: actions.FETCH_ATTRS_ERROR,
    payload: error,
  }),

  saveMarketingData: data => ({
    type: actions.SAVE_MARKETING_DATA,
    payload: data,
  }),
  saveViewData: data => ({
    type: actions.SAVE_VIEW_DATA,
    payload: data,
  }),
  saveMapiData: data => ({
    type: actions.SAVE_MAPI_DATA,
    payload: data,
  }),
  saveSelectedDecision: data => ({
    type: actions.SAVE_SELECTED_DECISION,
    payload: data,
  }),

  resetUserData: () => ({
    type: actions.RESET_USER_DATA,
  }),

  getDecision: data => ({
    type: actions.GET_DECISION,
    payload: data,
  }),
  getDecisionSuccess: data => ({
    type: actions.GET_DECISION_SUCCESS,
    payload: data,
  }),
  getDecisionError: error => ({
    type: actions.GET_DECISION_ERROR,
    payload: error,
  }),

  postDataToMapi: data => ({
    type: actions.POST_MAPI_DATA,
    payload: data,
  }),
  postDataToMapiSuccess: () => ({
    type: actions.POST_MAPI_DATA_SUCCESS,
  }),
};

export default actions;
