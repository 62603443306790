// Retreaver Code  !!!!! Do not touch this code !!!!!!!!

const retQ = window.retQ || {
  q: {},
  tags(args) {
    Object.keys(args).map(tag => {
      this.q[tag] = args[tag];
      return false;
    });
    // for (const tag in args) {
    //   this.q[tag] = args[tag];
    // }
  },
  empty() {
    return Object.keys(this.q).length === 0;
  },
  to_tags() {
    const ret = this.q;
    this.q = {};
    return ret;
  },
};

function RetreaverCode(pid, aid, campaignKey, callback) {
  const scriptElement = document.createElement('script');
  scriptElement.type = 'text/javascript';
  scriptElement.async = true;
  scriptElement.defer = true;
  scriptElement.src = `${document.location.protocol}//dist.routingapi.com/jsapi/v1/retreaver.min.js`;

  // eslint-disable-next-line no-multi-assign
  scriptElement.onload = scriptElement.onreadystatechange = () => {
    window.Retreaver.configure({
      host: 'api.routingapi.com',
      prefix: document.location.protocol === 'https:' ? 'https' : 'http',
    });
    console.log('RetreaverCode', pid, aid, campaignKey);
    const campaign = new window.Retreaver.Campaign({
      campaign_key: campaignKey,
    });

    retQ.tags({
      aid, // aid
      publisher_id: aid, // aid
      pid, // phone_pid
      sub_id: pid, // phone_pid
      source_url: window.location.href,
      s2: window.location.href,
    });
    campaign.request_number(retQ.to_tags(), number =>
      callback(
        number
          ? {
              phoneNumber: number.get('number'),
              phoneText: number.get('formatted_number'),
            }
          : null,
      ),
    );
  };
  (
    document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]
  ).appendChild(scriptElement);
}

export default RetreaverCode;
