/* eslint-disable prefer-promise-reject-errors */
import services from './services';

async function validateZipCode(_, value) {
  if (value.length !== 5) {
    return Promise.reject('error menor que 5');
  }
  const checkZipCode = await services
    .verifyZipCode('US', value)
    .then(respUs => respUs)
    .catch(() => services.verifyZipCode('PR', value).then(respPR => respPR))
    .catch(() => false);
  if (checkZipCode) {
    return Promise.resolve();
  }
  return Promise.reject('Invalid zip code');
}

const dynamicValidation = type => {
  const validations = {
    text: [
      {
        required: true,
        message: 'Please enter your name',
      },
    ],
    email: [
      {
        required: true,
        message: 'Please enter your email',
      },
      {
        type: 'email',
        message: 'Please enter a valid email',
      },
    ],
    zipcode: [
      { required: true, message: 'Please enter your zip code' },
      { pattern: /^\d+$/, message: 'Please enter a valid zip code' },
      { min: 5, message: 'Please enter a valid zip code' },
      { validator: validateZipCode, message: 'Please enter a valid zip code' },
    ],
    phone: [
      { required: true, message: 'Please enter your phone number' },
      {
        pattern: /^(\d{3})(\d{3})(\d{4})$/,
        message: 'Please enter a valid phone number',
      },
    ],
    dropdown: [
      {
        required: true,
        message: 'Please select an option',
      },
    ],
    card: [
      {
        required: true,
        message: 'Please select an option',
      },
    ],
  };
  return validations[type];
};

export default dynamicValidation;
