// process.env.REACT_APP_ENV;
const ENV =
  window.location.host === 'dev-dynamic-form-frontend.debt.com' ? 'production' : 'development';
// dev-dynamic-form-frontend.debt.com
// localhost:3000
console.log('stated like:', ENV);

const BASE_URL =
  ENV === 'production' ? 'https://form-cms-api.debt.com' : 'https://form-cms-api.debt.com';

const BASE_URL_DROPS_API = '';
// (ENV === 'production') ? 'https://drops-api.debt.com' : 'https://dev-drops-api.debt.com';

export { BASE_URL, BASE_URL_DROPS_API, ENV };
