/* eslint-disable no-useless-escape */
const getUrlParameter = name => {
  const newName = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${newName}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const getUrlDomain = () => window.location.hostname;

export { getUrlParameter, getUrlDomain };
