/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import axios from 'axios';

const createDashedUrl = (hostname, pathname = '') =>
  hostname ? `${hostname.split('.').join('-')}${pathname.split('/').join('-')}` : '';

const mappingVertical = identifier => {
  const vertical = {
    '106': 'credit_card_debt',
    '109': 'student_loan_debt',
    '110': 'tax_debt',
    '108': 'credit_correction',
    '115': 'bankruptcy',
    '111': 'collector_harassment',
    '112': 'debt_settlement',
  };
  return vertical[identifier];
};

const getUrlParams = querystring => {
  const queryString = decodeURIComponent(querystring.substring(1)).split('&');
  const paramsObj = {};
  if (queryString.length > 0) {
    queryString.map(param => {
      // check and change S# params name if any matched
      if (param) {
        const keyparam =
          param.split('=')[0].toLowerCase() === 's2'
            ? 'ckm_subid_2'
            : param.split('=')[0].toLowerCase() === 's3'
            ? 'ckm_subid_3'
            : param.split('=')[0].toLowerCase() === 's4'
            ? 'ckm_subid_4'
            : param.split('=')[0].toLowerCase() === 's5'
            ? 'ckm_subid_5'
            : param.split('=')[0].toLowerCase();
        // eslint-disable-next-line prefer-destructuring
        paramsObj[keyparam] = param.split('=')[1];
        return false;
      }
      return false;
    });
  }
  return paramsObj;
};

const getRetQValues = async (bParameter, dashedUrl) => {
  try {
    const bibValues = await axios
      .get(`https://www.debt.com/bib/${bParameter || dashedUrl}/?format=json&noo=sisisi`)
      .then(resp => (resp && resp.data ? resp.data : null))
      .catch(error => {
        throw new Error(error);
      });
    if (bibValues) {
      const { phone_pid: pid, aid, vertical, phone_campaign_key } = bibValues;
      return {
        pid,
        aid,
        phoneCampaignKey: phone_campaign_key || '',
        verticalsFromBib: vertical || [],
      };
    }
  } catch (error) {
    console.log(error);
    return {};
  }
};

const getTrustedForm = identifier =>
  document.getElementById(identifier) ? document.getElementById(identifier).value : null;

export { createDashedUrl, mappingVertical, getUrlParams, getRetQValues, getTrustedForm };
