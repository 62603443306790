import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import 'antd/dist/antd.css';
import './assets/styles/globalStyles.css';
import { createApp } from 'dcom-app/dist/containers/main/MainApp';
import currentCustomization from 'dcom-app/dist/settings/customConfigs/customizationProvider';
import bgImage from './assets/images/stripes-light.png';
import sideMenuOptions from './sideMenuOptions';
import { getAppRouters } from './routers';
import * as serviceWorker from './serviceWorker';
import reducer from './redux/reducers';
import sagas from './sagas';
import { init } from 'dcom-app/dist/sentry';
import { BASE_URL, ENV } from './config';

if (ENV === 'production') {
  const dsnKey = 'https://f2075245c4bf4642a9b11879e86f240d@o412973.ingest.sentry.io/5511796';
  const releaseName = 'dynamic-form-fe-release';
  init(dsnKey, releaseName);
}

const getSideMenuOptions = profile => {
  const menOptions = sideMenuOptions;

  if (!profile || !menOptions) return [];

  if (profile.roles.find(r => r.id === 1)) {
    return menOptions;
  }

  if (profile.roles.find(r => r.id === 2)) {
    return menOptions.filter(r => r.role.includes(2));
  }
  return null;
};

const currentDomain = window.location.hostname;

const customConfig = currentCustomization(currentDomain);

const config = {
  app: {
    customAppConfig: customConfig,
    theme: customConfig.theme,
    customStyle: null,
  },
  sideMenuOptions: getSideMenuOptions,
  modules: getAppRouters,
  services: {
    apiUrl: BASE_URL,
  },
  bgImage,
  customTopbar: null,
  customSidebar: null,
  customFooter: null,
  getReducers: () => reducer,
  getSagas: () => sagas,
};

const MainApp = createApp(config);

ReactDOM.render(
  <ThemeProvider theme={customConfig.theme}>{MainApp}</ThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
