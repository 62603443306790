const getFinalPid = (pidUrl, verticalsFromBib, defaultPid, selectedVertical) => {
  if (pidUrl) return pidUrl;
  const bibPid = verticalsFromBib
    ? verticalsFromBib.reduce((accum, currentElem) => {
        if (currentElem.vertical === selectedVertical) {
          accum = currentElem.pid;
        }
        return accum;
      }, '')
    : '';
  if (bibPid) return bibPid;
  return defaultPid;
};

export default getFinalPid;
