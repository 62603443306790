import actions from './actions';
import { storageGet, storageSet } from '../../helpers/sessionStorage';

const initState = {
  loadingForWorksheet: false,
  budgetWorkSheet: storageGet('budgetWorkSheet') || {},
  dynamicRoutes: storageGet('dynamicRoutes') || [],
  loadingForRouting: false,
  attrsGroupByCateg: storageGet('attrsGroupByCateg') || {},
  loadingForDecision: false,
  decisions: [],
  isError: false,
  loadingForMapi: false,
  marketingData: storageGet('marketingData') || {},
  userData: storageGet('userData') || {},
  mapiData: storageGet('mapiData') || {},
  selectedDecisionData: storageGet('selectedDecisionData') || {},
  errorMessage: '',
  visualConfig: storageGet('visualConfig') || '',
  thankYouPage: storageGet('thankYouPage') || '',
};

export default function reducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case action.GET_WORKSHEET: {
      return {
        ...state,
        loadingForWorksheet: true,
      };
    }
    case actions.GET_WORKSHEET_SUCCESS: {
      storageSet('budgetWorkSheet', payload);
      return {
        ...state,
        loadingForWorksheet: false,
        isError: false,
        budgetWorkSheet: payload,
      };
    }
    case actions.GET_WORKSHEET_ERROR: {
      return {
        ...state,
        loadingForWorksheet: false,
        isError: true,
        errorMessage: 'Budget Worksheet request failed. Please come back later',
      };
    }
    case actions.FETCH_ATTRS: {
      return {
        ...state,
        loadingForRouting: true,
      };
    }
    case actions.FETCH_ATTRS_SUCCESS: {
      const { dynamicRoutes, visualConfig, attrsGroupByCateg, thankYouPage } = payload;
      storageSet('dynamicRoutes', dynamicRoutes);
      storageSet('attrsGroupByCateg', attrsGroupByCateg);
      storageSet('thankYouPage', thankYouPage);
      storageSet('visualConfig', visualConfig);
      return {
        ...state,
        isError: false,
        dynamicRoutes,
        attrsGroupByCateg,
        thankYouPage,
        visualConfig,
        loadingForRouting: false,
      };
    }
    case actions.FETCH_ATTRS_ERROR: {
      return {
        ...state,
        loadingForRouting: false,
        isError: true,
        errorMessage: 'Attrs request failed. Please come back later',
      };
    }

    case actions.SAVE_VIEW_DATA: {
      storageSet('userData', payload);
      return {
        ...state,
        userData: payload,
      };
    }

    case actions.SAVE_MAPI_DATA: {
      const prevData = storageGet('mapiData') || {};
      storageSet('mapiData', { ...prevData, ...payload });
      return {
        ...state,
        mapiData: { ...state.mapiData, ...payload },
      };
    }

    case actions.SAVE_MARKETING_DATA: {
      const prevData = storageGet('marketingData') || {};
      storageSet('marketingData', { ...prevData, ...payload });
      return {
        ...state,
        marketingData: { ...state.marketingData, ...payload },
      };
    }

    case actions.SAVE_SELECTED_DECISION: {
      const prevData = storageGet('selectedDecisionData');
      storageSet('selectedDecisionData', { ...prevData, ...payload });
      return {
        ...state,
        selectedDecisionData: { ...state.selectedDecisionData, ...payload },
      };
    }

    case actions.RESET_USER_DATA: {
      storageSet('userData', {});
      storageSet('mapiData', {});
      return {
        ...state,
        userData: {},
        mapiData: {},
      };
    }

    case actions.GET_COMPANY_INFO: {
      return {
        ...state,
        loadingForCompany: true,
      };
    }
    case actions.GET_COMPANY_INFO_SUCCESS: {
      storageSet('companyInfo', payload);
      return {
        ...state,
        loadingForCompany: false,
        isError: false,
        companyInfo: payload,
      };
    }
    case actions.GET_COMPANY_INFO_ERROR: {
      return {
        ...state,
        loadingForCompany: false,
        isError: true,
        errorMessage: 'Error from company info endpoint',
      };
    }
    case actions.GET_DECISION: {
      return {
        ...state,
        loadingForDecision: true,
      };
    }
    case actions.GET_DECISION_SUCCESS: {
      return {
        ...state,
        loadingForDecision: false,
        isError: false,
        decisions: payload,
      };
    }
    case actions.GET_DECISION_ERROR: {
      return {
        ...state,
        loadingForDecision: false,
        isError: true,
        errorMessage: 'Error from decision engine',
      };
    }
    case actions.POST_MAPI_DATA: {
      return {
        ...state,
        loadingForMapi: true,
      };
    }
    case actions.POST_MAPI_DATA_SUCCESS: {
      return {
        ...state,
        loadingForMapi: false,
      };
    }
    default:
      return state;
  }
}
