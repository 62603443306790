import axios from 'axios';

const services = {
  verifyZipCode: (country, zipCode) =>
    axios.get(`https://zip.getziptastic.com/v2/${country}/${zipCode}?_=1544457763845`),
  getUserState: zipCode =>
    axios
      .get(`https://zip.getziptastic.com/v2/US/${zipCode}?_=1544457763845`)
      .then(({ data }) => data.state_short)
      .catch(() =>
        axios
          .get(`https://zip.getziptastic.com/v2/PR/${zipCode}?_=1544457763845`)
          .then(() => 'PR')
          .catch(error => console.log('Error occured', error)),
      ),
};

export default services;
