import service from 'dcom-app/dist/services';
import axios from 'axios';

const mapiService = () => axios.create({ baseURL: 'https://fam.debt.com' });

const ipAddressService = () => axios.create({ baseURL: 'https://api.ipify.org?format=json' });

const servicesApi = {
  getWorkSheet: payload =>
    service()
      .post('/budgetworksheet', payload)
      .then(response => response.data)
      .catch(error => error),
  fetchAttrs: hostname =>
    service()
      .get(`/screen_attributes/?url=${hostname}`)
      .then(response => response.data.data)
      .catch(error => error),
  list: () =>
    service()
      .get('/companyinfo')
      .then(response => response.data)
      .catch(error => error),
  getDecision: payload =>
    service()
      .post(`/decision_engine`, payload)
      .then(response => response.data)
      .catch(error => error),
  // MAPI API
  getMapiAuthToken: payload =>
    mapiService()
      .post('/signin', payload, {
        headers: { 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json' },
      })
      .then(response => response.data)
      .catch(error => error),
  postDataMapi: (payload, token) =>
    mapiService()
      .post('/api/v1/createLead', payload, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          Authorization: `${`Bearer ${token}`}`,
        },
      })
      .then(response => response.data)
      .catch(error => error),
  getUserIpAddress: () =>
    ipAddressService()
      .get()
      .then(response => response.data.ip)
      .catch(() => '192.206.72.130'),
};

export default servicesApi;
