/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { all, takeEvery, call, put, select, delay } from 'redux-saga/effects';
import { message as MessageManager } from 'antd';
import { historyG as history } from 'dcom-app/dist/containers/main/MainApp';
import servicesApi from './services';
import zipService from '../../helpers/services';
import actions from './actions';
import { getTrustedForm, getFinalPid } from '../../helpers/helpers';

const getMapiData = state => state.DynamicFormApp.mapiData;
const getSelectedDecisionData = state => state.DynamicFormApp.selectedDecisionData;
const getMarketingData = state => state.DynamicFormApp.marketingData;

function* getWorkSheetSaga({ payload }) {
  try {
    const workSheet = yield call(servicesApi.getWorkSheet, payload);
    if (!workSheet.isAxiosError) {
      yield put(actions.getWorkSheetSuccess(workSheet));
      return;
    }
    // error
    const { message } = workSheet;
    console.log('Error: incomeAttrs:-->', message);
    yield put(actions.getWorkSheetError({ error: message }));

    if (workSheet.response && workSheet.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    console.log(error);
    yield put(actions.getWorkSheetError(error));
  }
}

function* fetchAttrsSaga() {
  const domain = `${window.location.hostname.replace(/\/$/, '')}`;
  try {
    const [attrs, companyInfo] = yield all([
      call(servicesApi.fetchAttrs, window.btoa(domain)),
      call(servicesApi.list),
    ]);
    if (!attrs.isAxiosError && !companyInfo.isAxiosError) {
      const cleanAttrs = attrs.filter(attr => attr.attribute).reverse();
      // get routes
      const dynamicRoutes = [];
      cleanAttrs.map(({ attribute }) => {
        const exist = dynamicRoutes.find(route => route.id === attribute.categoryId.id);
        if (!exist) {
          return dynamicRoutes.push({
            layout: attribute.categoryId.name === 'Info Confirmation' ? 'twoColumns' : 'oneColumn',
            id: attribute.categoryId.id,
            category: attribute.categoryId.name,
            path: attribute.categoryId.slug,
          });
        }
      });
      // const allAttributes = yield call(getGroups, cleanAttrs); // array with types ['debtamount','credit scrore']
      const allAttributes = cleanAttrs.map(({ attribute }) => attribute.categoryId.name);
      const attrsGroupByCateg = {};
      allAttributes.map(categ => {
        attrsGroupByCateg[categ] = cleanAttrs.filter(attr => attr.attribute.type === categ);
        return false;
      });
      // get thank_you_page
      const { thank_you_page: thankYouPage, config: visualConfig } = companyInfo.find(
        info => info.url === domain,
      );
      yield put(
        actions.fetchAttrsSuccess({
          dynamicRoutes,
          attrsGroupByCateg,
          thankYouPage,
          visualConfig,
        }),
      );
      return;
    }
    // error
    const { message } = attrs;
    console.log('Error: paychecks:-->', message);
    yield put(actions.fetchAttrsError({ error: message }));

    if (attrs.response && attrs.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    console.log(error);
    yield put(actions.fetchAttrsError(error));
  }
}

function* getDecisionSaga({ payload }) {
  try {
    // GET USER STATE AND ADD IT TO THE PAYLOAD
    const { zip } = payload;
    const userState = yield call(zipService.getUserState, zip);
    const payloadWithUserState = { ...payload, userState };
    // SEND PAYLOAD TO THE DECISION ENGINE
    const resp = yield call(servicesApi.getDecision, payloadWithUserState);
    if (!resp.isAxiosError) {
      const data = resp.data.length !== 0 ? resp.data.map(decision => decision.type) : [];
      yield delay(5000);
      yield put(actions.getDecisionSuccess(data));
      return;
    }
    // error
    const { message } = resp;
    console.log('Error: incomeAttrs:-->', message);
    yield put(actions.getDecisionError({ error: message }));

    if (resp.response && resp.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    yield put(actions.getDecisionError(error));
  }
}

function* postDataToMapi() {
  // get pid, aid and vertical
  const { bibValues, pid, aid, defaultPid, defaultAid, aidRetreaver } = yield select(
    getMarketingData,
  );
  const { verticalsFromBib } = bibValues;
  const { vertical } = yield select(getSelectedDecisionData);
  const finalPid = yield call(getFinalPid, pid, verticalsFromBib, defaultPid, vertical);
  const finalAid = aid || aidRetreaver || defaultAid;
  // get personal info
  const { cc_debt_amount, first_name, last_name, email, primary_phone } = yield select(getMapiData);
  // get marketing info
  const { source, url, urlParams, utm_content } = yield select(getMarketingData);
  // get Trustedform tokens
  const xxTrustedFormToken = yield call(getTrustedForm, 'xxTrustedFormToken_0');
  const xxTrustedFormCertUrl = yield call(getTrustedForm, 'xxTrustedFormCertUrl_0');
  try {
    const authToken = yield call(servicesApi.getMapiAuthToken, {
      url: window.location.hostname,
      pid: finalPid,
    });
    // get AUTH token
    const { token } = authToken;
    // get user IP address
    const ip_address = yield call(servicesApi.getUserIpAddress);
    // final payload
    const finalMapiPayload = {
      cc_debt_amount,
      first_name,
      last_name,
      email,
      primary_phone,
      xxTrustedFormToken,
      xxTrustedFormCertUrl,
      opt_in: 1,
      source,
      url,
      urlParams: { ...urlParams, pid: finalPid, aid: finalAid },
      utm_content,
      pid: finalPid,
      aid: finalAid,
      vertical,
      ip_address,
    };
    const data = yield call(servicesApi.postDataMapi, finalMapiPayload, token);
  } finally {
    // remove loading and redirect no matter if MAPI fails or not

    const { redirect } = yield select(getSelectedDecisionData);
    if (redirect) {
      // const redirectUrl = encodeURIComponent(`${redirect}`);
      window.location.assign(redirect);
    }
    yield put({ type: actions.POST_MAPI_DATA_SUCCESS });
    // if (!redirect && nextView) history.push(nextView);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_WORKSHEET, getWorkSheetSaga)]);
  yield all([takeEvery(actions.FETCH_ATTRS, fetchAttrsSaga)]);
  yield all([takeEvery(actions.GET_DECISION, getDecisionSaga)]);
  yield all([takeEvery(actions.POST_MAPI_DATA, postDataToMapi)]);
}
