import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import asyncComponent from 'dcom-app/dist/helpers/AsyncFunc';
import MainApp from 'dcom-app/dist/containers/App/App';

// eslint-disable-next-line no-sparse-arrays
const modulesRoutes = [
  {
    path: '',
    exact: false,
    component: asyncComponent(() => import('./containers/DynamicForm/DynamicForm')),
    roles: [],
  },
  // only for testing
  {
    path: 'test',
    exact: true,
    component: asyncComponent(() => import('./containers/DynamicResults/ResultsView')),
    roles: [],
  },
];

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

export const getPublicRouters = roles => {
  return modulesRoutes.filter(v => v.roles.length === 0);
};

export const getPrivateRouters = roles => {
  return modulesRoutes.filter(
    r => r.roles.length > 0 && r.roles.some(value => roles.includes(value)),
  );
};

export const getAppRouters = (roles, isLoggedIn) => {
  const publicRouters = getPublicRouters(roles);
  const privateRouters = getPrivateRouters(roles);

  return (
    <Switch>
      {publicRouters.map(p => (
        <Route key={p.path} exact={p.exact !== false} path={`/${p.path}`} component={p.component} />
      ))}
      <RestrictedRoute
        path="/budget"
        component={MainApp}
        isLoggedIn={isLoggedIn}
        privateRouters={privateRouters}
        customTopbar={null}
        customSidebar={null}
        customFooter={null}
      />
      <Route component={asyncComponent(() => import('./containers/not-found'))} />
    </Switch>
  );
};
